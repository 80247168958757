@import './slick.css';
@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body,
html,
#root {
  display: flex;
  width: 100%;
}

#root {
  flex-direction: column;
}
.slick-active {
  z-index: 10;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
