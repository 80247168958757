@font-face {
  font-family: 'pbl-mobile-fonticon';
  src: url('../fonts/pbl-mobile-fonticon.eot');
  src: url('../fonts/pbl-mobile-fonticon.eot') format('embedded-opentype'),
    url('../fonts/pbl-mobile-fonticon.ttf') format('truetype'),
    url('../fonts/pbl-mobile-fonticon.woff') format('woff'),
    url('../fonts/pbl-mobile-fonticon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pbl-mobile-fonticon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
  content: "\e912";
}
.icon-notification:before {
  content: "\e913";
}
.icon-copy:before {
  content: "\e914";
}
.icon-arrow-backward:before {
  content: "\e915";
}
.icon-arrow-forward:before {
  content: "\e916";
}
.icon-barcode-Scan:before {
  content: "\e917";
}
.icon-asterix:before {
  content: "\e918";
}
.icon-games-filled:before {
  content: "\e919";
}
.icon-home:before {
  content: "\e91a";
}
.icon-earn:before {
  content: "\e91b";
}
.icon-rewards:before {
  content: "\e91c";
}
.icon-menu:before {
  content: "\e91d";
}
.icon-retailer:before {
  content: "\e91e";
}
.icon-calendar:before {
  content: "\e91f";
}
.icon-camera:before {
  content: "\e920";
}
.icon-check:before {
  content: "\e921";
}
.icon-checkbox-checked:before {
  content: "\e922";
}
.icon-checkbox-unchecked:before {
  content: "\e923";
}
.icon-chevron-down:before {
  content: "\e924";
}
.icon-chevron-left:before {
  content: "\e925";
}
.icon-chevron-right:before {
  content: "\e926";
}
.icon-chevron-up:before {
  content: "\e927";
}
.icon-close:before {
  content: "\e928";
}
.icon-contact:before {
  content: "\e929";
}
.icon-delete-2:before {
  content: "\e92a";
}
.icon-edit:before {
  content: "\e92b";
}
.icon-favorite-filled:before {
  content: "\e92c";
}
.icon-favorite-line:before {
  content: "\e92d";
}
.icon-games-line:before {
  content: "\e92e";
}
.icon-gear:before {
  content: "\e92f";
}
.icon-help:before {
  content: "\e930";
}
.icon-hide:before {
  content: "\e931";
}
.icon-information:before {
  content: "\e932";
}
.icon-loading-indicator:before {
  content: "\e933";
}
.icon-location:before {
  content: "\e934";
}
.icon-lock:before {
  content: "\e935";
}
.icon-mail:before {
  content: "\e936";
}
.icon-minus:before {
  content: "\e937";
}
.icon-phone:before {
  content: "\e938";
}
.icon-play-button:before {
  content: "\e939";
}
.icon-plus-small:before {
  content: "\e93a";
}
.icon-plus:before {
  content: "\e93b";
}
.icon-print:before {
  content: "\e93c";
}
.icon-radio-button-selected:before {
  content: "\e93d";
}
.icon-radio-button:before {
  content: "\e93e";
}
.icon-remove:before {
  content: "\e93f";
}
.icon-refresh:before {
  content: "\e940";
}
.icon-save:before {
  content: "\e941";
}
.icon-search:before {
  content: "\e942";
}
.icon-share:before {
  content: "\e943";
}
.icon-show:before {
  content: "\e944";
}
.icon-sign-In:before {
  content: "\e945";
}
.icon-success:before {
  content: "\e946";
}
.icon-video-cam:before {
  content: "\e947";
}
.icon-warning:before {
  content: "\e948";
}
.icon-manual-entry:before {
  content: "\e949";
}
.icon-draws:before {
  content: "\e94a";
}
.icon-search-no-results:before {
  content: "\e94b";
}
.icon-promo-code:before {
  content: "\e94c";
}
.icon-sign-out:before {
  content: "\e94d";
}
.icon-history:before {
  content: "\e94e";
}
.icon-account:before {
  content: "\e94f";
}
.icon-cart:before {
  content: "\e950";
}
.icon-home-2:before {
  content: "\e951";
}
.icon-lucke-reward:before {
  content: "\e952";
}
.icon-sign-in-earn:before {
  content: "\e953";
}
.icon-sign-in-filled:before {
  content: "\e954";
}
.icon-scratch:before {
  content: "\e955";
}
.icon-drawings:before {
  content: "\e956";
}
.icon-nc-rewards-solid:before {
  content: "\e957";
}
.icon-nc-sign-in-solid:before {
  content: "\e958";
}
.icon-nc-menu-solid:before {
  content: "\e959";
}
.icon-nc-home-solid:before {
  content: "\e95a";
}
.icon-nc-help-solid:before {
  content: "\e95b";
}
.icon-nc-earn-solid:before {
  content: "\e95c";
}
.icon-nc-cart-solid:before {
  content: "\e95d";
}
.icon-nc-buy-solid:before {
  content: "\e95e";
}
.icon-nc-account-solid:before {
  content: "\e95f";
}
.icon-nc-2nd-chance-solid:before {
  content: "\e960";
}
.icon-nc-sign-In-line:before {
  content: "\e961";
}
.icon-nc-rewards-line:before {
  content: "\e962";
}
.icon-nc-home-line:before {
  content: "\e963";
}
.icon-nc-help-line:before {
  content: "\e964";
}
.icon-nc-earn-line:before {
  content: "\e965";
}
.icon-nc-cart-line:before {
  content: "\e966";
}
.icon-nc-buy-line:before {
  content: "\e967";
}
.icon-nc-barcode-line:before {
  content: "\e968";
}
.icon-nc-account-line:before {
  content: "\e969";
}
.icon-nc-2nd-chance-line:before {
  content: "\e96a";
}
.icon-error:before {
  content: "\e96b";
}
.icon-lucky-lounge:before {
  content: "\e900";
}
